.hero {
  height: 80vh;
  background: rgba(128, 128, 128, 0.402) url(./assets/cool-background.png) center no-repeat;
  background-size: cover;
}

.hero-title-text {
  font-size: 550%;
  background-image: linear-gradient(90deg, rgb(0, 54, 116) 0%, rgb(39, 132, 203) 35%, rgb(142, 233, 252) 100%) !important;
  background-clip: text !important;
  -webkit-text-fill-color: transparent;
  text-fill-color: transparent !important;
}

.app-dark {
  background-color: var(--dark-gray) !important;
}

.welcome-text {
  color: white;
  font-size: 180%;
}


.app-list-container { 
  background-size: cover;
  background-position: center center;
  background-repeat: repeat;
  background-image: url(./assets/magicpattern-grid.png);
}


.list-text {
  font-size: 260%;
  color: white;
  font-weight: 700;
}

.app-list-card {
  width: 80% !important;
  border-radius: 49px !important;
  background: linear-gradient(145deg, #242424, #00000090) !important;
  box-shadow:  8px 8px 16px #000000,
             -8px -8px 16px #242424 !important;
}

.app-list-card img {
  height: 300px !important;
  object-fit: cover !important;
  width: 100% !important;
  border-radius: 50px 0px 0px 50px !important;
}

.card-title {
  color: white;
  font-weight: 700;
  font-size: 210%;
}

@media only screen and (max-width: 767px) {
  .app-list-card img {
    border-radius: 50px 50px 0px 0px !important;
  }
}